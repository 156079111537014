import React from "react";
import { Link } from 'react-router-dom';
import _ from "lodash";
import settings from "./settings";
import ContactForm from "./ContactForm";


function Contact() {
    const contact = _.get(settings, "address", []);
    return (
        <>
            <header>
                <div className="container mt-5">
                    <div className="page-banner">
                        <div className="row justify-content-center align-items-center h-100">
                            <div className="col-md-6">
                                <nav aria-label="Breadcrumb">
                                    <ul className="breadcrumb justify-content-center py-0 bg-transparent">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">Contact</li>
                                    </ul>
                                </nav>
                                <h1 className="text-center">Contact Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <main>
                <div className="page-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 py-3">
                                <h2 className="title-section">Get in Touch</h2>
                                <div className="divider"></div>
                                <p>A new journey builder for many startups and small business to run there world into successful stories! feel free to Contact Us.</p>

                                <ul className="contact-list">
                                    <li style={{ display: 'table' }}>
                                        <div className="icon"><span className="mai-map"></span></div>
                                        <div className="content" style={{ display: 'table-cell' }}>
                                        <span><b>Head Quarters:</b> <br/>{contact['headQuarters']}</span>
                                        </div>
                                    </li>
                                    <li style={{ display: 'table' }}>
                                        <div className="icon"><span className="mai-map"></span></div>
                                        <div className="content" style={{ display: 'table-cell' }}>
                                        <span><b>Development Office:</b> <br/>{contact['devOffice']}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon"><span className="mai-mail"></span></div>
                                        <div className="content"><a href={`mailto:${contact['email']}`}>{contact['email']}</a></div>
                                    </li>
                                    <li>
                                        <div className="icon"><span className="mai-phone-portrait"></span></div>
                                        <div className="content"><a href={`tel:${contact['phoneno']}`}>{contact['phoneno']}</a></div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6 py-3">
                                <div className="subhead">Contact Us</div>
                                <h2 className="title-section">Drop Us a Line</h2>
                                <div className="divider"></div>
                                <ContactForm />
                            </div>
                        </div>
                        <div className="map-main">
                            {/* eslint-disable-next-line */}
                            <iframe width="100%" height="300px" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={contact['mapLink']}></iframe>
                        </div>
                    </div>
                    
                </div>
            </main>
        </>
    )
}

export default Contact;
