import React from "react";
import { Link } from 'react-router-dom';
import _ from "lodash";
import settings from "./settings";


function Products() {
    const products = _.get(settings, "products", []);

    return (
        <>
            <header>
                <div className="container mt-5">
                    <div className="page-banner">
                        <div className="row justify-content-center align-items-center h-100">
                            <div className="col-md-6">
                                <nav aria-label="Breadcrumb">
                                    <ul className="breadcrumb justify-content-center py-0 bg-transparent">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">Products</li>
                                    </ul>
                                </nav>
                                <h1 className="text-center">Products</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="back-to-top"></div>
            <main>
                <div className="page-section">
                    <div className="container">
                        <div className="row">
                            {
                                _.map(products, (product, i) => {
                                    return (
                                        <div className="col-md-6 col-lg-4 py-3" key={i}>
                                            <div className="card-product">
                                                <div className="header">
                                                    <div className="entry-footer">
                                                        <div className="avatar">
                                                            <img src={product['photo']} alt={product['name']} height="150px" width="150px"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="body">
                                                    <div className="post-title"><Link to={product['link']}>{product['name']}</Link></div>
                                                    <div className="post-excerpt">{product['description']}</div>
                                                </div>
                                                <div className="footer">
                                                    <Link to={product['link']} target="_blank" rel="noreferrer" >Visit Product Site <span className="mai-chevron-forward text-sm"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Products;
