import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import WOW from 'wowjs';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';



class App extends React.Component {
  componentDidMount() {
    new WOW.WOW({
      live: false
    }).init();
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          
            <Header></Header>
            <Footer></Footer>
          
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
