import React from "react";
import { Link } from 'react-router-dom';
import _ from "lodash";
import settings from "./settings";


function Services() {
    const benefits = _.get(settings, "benefits", []);
    return (
        <>
            <header>
                <div className="container mt-5">
                    <div className="page-banner">
                        <div className="row justify-content-center align-items-center h-100">
                            <div className="col-md-6">
                                <nav aria-label="Breadcrumb">
                                    <ul className="breadcrumb justify-content-center py-0 bg-transparent">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">Services</li>
                                    </ul>
                                </nav>
                                <h1 className="text-center">Services</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <main>
                <div className="page-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 py-3">
                                <h2 className="title-section">We're <span className="marked">ready to</span> Serve you with best</h2>
                                <div className="divider"></div>
                                <p className="mb-5">{settings['descriptorTag']}</p>
                                <Link to="#" className="btn btn-primary">More Details</Link>
                                <Link to="#" className="btn btn-outline ml-2">See pricing</Link>
                            </div>
                            <div className="col-lg-6 py-3">
                                <div className="img-place text-center">
                                    <img src="../assets/img/bg_image_3.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section">
                    <div className="container">
                        <div className="text-center">
                            <div className="subhead">Why Choose Us</div>
                            <h2 className="title-section">Your <span className="marked">Comfort</span> is Our Priority</h2>
                            <div className="divider mx-auto"></div>
                        </div>

                        <div className="row mt-5 text-center">
                            {
                                _.map(benefits, (benefit, i) => {
                                    return (
                                        <div className="col-lg-4 py-3 wow fadeInUp" key={i}>
                                            <div className="display-3"><span className="mai-shapes"></span></div>
                                            <h5>{benefit['title']}</h5>
                                            <p>{benefit['description']}</p>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Services;
