import React from "react";
import { Link } from 'react-router-dom';
import _ from "lodash";
import settings from "./settings";


function Blog() {
    const blogs = _.get(settings, "blogs", []);

    return (
        <>
            <header>
                <div className="container mt-5">
                    <div className="page-banner">
                        <div className="row justify-content-center align-items-center h-100">
                            <div className="col-md-6">
                                <nav aria-label="Breadcrumb">
                                    <ul className="breadcrumb justify-content-center py-0 bg-transparent">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">News</li>
                                    </ul>
                                </nav>
                                <h1 className="text-center">News</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <main>
                <div className="page-section">
                    <div className="container">
                        <div className="row">
                            {
                                _.map(blogs, (blog, i) => {
                                    return (
                                        <div className="col-md-6 col-lg-4 py-3" key={i}>
                                            <div className="card-blog">
                                                <div className="header">
                                                    <div className="avatar">
                                                        <img src={blog['photo']} alt={blog['author']} />
                                                    </div>
                                                    <div className="entry-footer">
                                                        <div className="post-author">{blog['author']}</div>
                                                        <Link to="#" className="post-date">{blog['date']}</Link>
                                                    </div>
                                                </div>
                                                <div className="body">
                                                    <div className="post-title"><Link to={blog['link']}>{blog['title']}</Link></div>
                                                    <div className="post-excerpt">{blog['description']}</div>
                                                </div>
                                                <div className="footer">
                                                    <Link to={blog['link']}>Read More <span className="mai-chevron-forward text-sm"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                          
                            <div className="col-12 mt-5">
                                <nav aria-label="Page Navigation">
                                    <ul className="pagination justify-content-center">
                                        <li className="page-item disabled">
                                            <Link className="page-link" to="#" tabIndex="-1" aria-disabled="true">Previous</Link>
                                        </li>
                                        <li className="page-item active" aria-current="page">
                                            <Link className="page-link" to="#">1 <span className="sr-only">(current)</span></Link>
                                        </li>
                                        <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                                        <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                                        <li className="page-item">
                                            <Link className="page-link" to="#">Next</Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>

                        </div>

                    </div>
                </div>
            </main>
        </>
    )
}

export default Blog;
