import React from "react";
import { Link } from 'react-router-dom';
import _ from "lodash";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import settings from "./settings";
import ContactForm from "./ContactForm";


function Home() {
    const features = _.get(settings, "features", []);
    const contact = _.get(settings, "address", {});
    const revenue = _.get(settings, "revenue", {});
    const benefits = _.get(settings, "benefits", []);
    const pricingPlans = _.get(settings, "pricing", []);
    const reviews = _.get(settings, "reviews", []);
    const blogs = _.get(settings, "blogs", []);
    const clients = _.get(settings, "clients", []);

    return (
        <>
            <header>
                <div className="page-banner home-banner">
                    <div className="container h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-lg-6 py-3 wow fadeInUp">
                                <h1 className="mb-4">Great Companies are built on great Products</h1>
                                <p className="text-lg mb-5">Ignite the most powerfull growth engine you have ever built for your company</p>

                                <Link to="#" className="btn btn-outline border text-secondary">More Info</Link>
                                <Link to="#" className="btn btn-primary btn-split ml-2">Watch Video <div className="fab"><span className="mai-play"></span></div></Link>
                            </div>
                            <div className="col-lg-6 py-3 wow zoomIn">
                                <div className="img-place">
                                    <img src="../assets/img/bg_image_1.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <main>
                <div className="page-section features">
                    <div className="container">
                        <div className="row justify-content-center">
                            {
                                _.map(features, (feature, i) => {
                                    return (<div className="col-md-6 col-lg-4 py-3 wow fadeInUp" key={i}>
                                    <div className="d-flex flex-row">
                                        <div className="img-fluid mr-3">
                                            <img src="../assets/img/icon_pattern.svg" alt="" />
                                        </div>
                                        <div>
                                            <h5>{feature['title']}</h5>
                                            <p>{feature['description']}</p>
                                        </div>
                                    </div>
                                </div>)
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="page-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 py-3 wow zoomIn">
                                <div className="img-place text-center">
                                    <img src="../assets/img/bg_image_2.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 py-3 wow fadeInRight">
                                <h2 className="title-section">We're <span className="marked">Dynamic</span> Team of Creatives People</h2>
                                <div className="divider"></div>
                                <p>{settings['descriptorTag']}</p>
                                <div className="img-place mb-3">
                                    <img src="../assets/img/testi_image.png" alt="" />
                                </div>
                                <Link to="#" className="btn btn-primary">More Details</Link>
                                <Link to="#" className="btn btn-outline border ml-2">Success Stories</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section counter-section">
                    <div className="container">
                        <div className="row align-items-center text-center">
                            <div className="col-lg-4">
                                <p>Total Invest</p>
                                <h2>{settings['currency']}<span className="number" data-number={revenue['totalInvest']}></span></h2>
                            </div>
                            <div className="col-lg-4">
                                <p>Yearly Revenue</p>
                                <h2>{settings['currency']}<span className="number" data-number={revenue['yearlyRevenue']}></span></h2>
                            </div>
                            <div className="col-lg-4">
                                <p>Growth Ration</p>
                                <h2><span className="number" data-number={revenue['growthRatio']}></span>%</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 py-3 wow fadeInLeft">
                                <h2 className="title-section">We're <span className="marked">ready to</span> Serve you with best</h2>
                                <div className="divider"></div>
                                <p className="mb-5">{settings['descriptorTag']}</p>
                                <Link to="#" className="btn btn-primary">More Details</Link>
                                <Link to="#" className="btn btn-outline ml-2">See pricing</Link>
                            </div>
                            <div className="col-lg-6 py-3 wow zoomIn">
                                <div className="img-place text-center">
                                    <img src="../assets/img/bg_image_3.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section">
                    <div className="container">
                        <div className="text-center wow fadeInUp">
                            <div className="subhead">Why Choose Us</div>
                            <h2 className="title-section">Your <span className="marked">Comfort</span> is Our Priority</h2>
                            <div className="divider mx-auto"></div>
                        </div>

                        <div className="row mt-5 text-center">
                            {
                                _.map(benefits, (benefit, i) => {
                                    return (
                                        <div className="col-lg-4 py-3 wow fadeInUp" key={i}>
                                            <div className="display-3"><span className="mai-shapes"></span></div>
                                            <h5>{benefit['title']}</h5>
                                            <p>{benefit['description']}</p>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="page-section border-top pricing" id="pricing">
                    <div className="container">
                        <div className="text-center wow fadeInUp">
                            <h2 className="title-section">Pricing Plan</h2>
                            <div className="divider mx-auto"></div>
                        </div>

                        <div className="row justify-content-center">
                            {
                                _.map(pricingPlans, (pricing, i) => {
                                    return (
                                        <div className="col-12 col-lg-auto py-3 wow fadeInLeft" key={i}>
                                            <div className={`card-pricing ${pricing['recommended']? 'active': ''}`}>
                                                <div className="header">
                                                    {pricing['recommended'] && <div className="price-labled">Best</div>}
                                                    <div className="price-icon"><span className={pricing['icon']}></span></div>
                                                    <div className="price-title">{pricing['title']}</div>
                                                </div>
                                                <div className="body py-3">
                                                    <div className="price-tag">
                                                        <span className="currency">{settings['currency']}</span>
                                                        <h2 className="display-4">{pricing['priceMonthly']}</h2>
                                                        <span className="period">/monthly</span>
                                                    </div>
                                                    <div className="price-tag">
                                                        <span className="currency">{settings['currency']}</span>
                                                        <h2 className="display-6">{pricing['priceAnnualy']}</h2>
                                                        <span className="period">/yearly</span>
                                                        {pricing['discount'] && <span className="display"> ( {pricing['discount']}% Off )</span>}
                                                    </div>
                                                    <div className="price-info">
                                                        <p>Choose the plan that right for you</p>
                                                    </div>
                                                </div>
                                                <div className="footer">
                                                    <Link to="#" className="btn btn-outline rounded-pill">Choose Plan</Link>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="page-section bg-light">
                    <div className="container">
                        <div id="testimonials">
                            {reviews ? 
                                <OwlCarousel className="owl-carousel wow fadeInUp owl-theme" margin={10} items={1} autoHeight rewind autoplayHoverPause loop autoplay animateOut={'fadeOut'} animateIn={'fadeIn'} >
                                    {
                                        _.map(reviews, (review, i) => {
                                            return (
                                                <div className="item" key={i}>
                                                    <div className="row align-items-center">
                                                        <div className="col-md-6 py-3">
                                                            <div className="testi-image">
                                                                <img src={review['photo']} alt={review['name']} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 py-3">
                                                            <div className="testi-content">
                                                                <p>{review['comment']}</p>
                                                                <div className="entry-footer">
                                                                    <strong>{review['name']}</strong> &mdash; <span className="text-grey">{review['designation']} {review['company']}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </OwlCarousel>
                            : null }
                        </div>
                    </div>
                </div>

                <div className="page-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 py-3 wow fadeInUp">
                                <h2 className="title-section">Get in Touch</h2>
                                <div className="divider"></div>
                                <p>A new journey builder for many startups and small business to run there world into successful stories! feel free to Contact Us.</p>

                                <ul className="contact-list">
                                <li style={{ display: 'table' }}>
                                        <div className="icon"><span className="mai-map"></span></div>
                                        <div className="content" style={{ display: 'table-cell' }}>
                                            <span><b>Head Quarters:</b> <br/>{contact['headQuarters']}</span>
                                        </div>
                                    </li>
                                    <li style={{ display: 'table' }}>
                                        <div className="icon"><span className="mai-map"></span></div>
                                        <div className="content" style={{ display: 'table-cell' }}>
                                            <span><b>Development Office:</b> <br/>{contact['devOffice']}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon"><span className="mai-mail"></span></div>
                                        <div className="content"><a href={`mailto:${contact['email']}`}>{contact['email']}</a></div>
                                    </li>
                                    <li>
                                        <div className="icon"><span className="mai-phone-portrait"></span></div>
                                        <div className="content"><a href={`tel:${contact['phoneno']}`}>{contact['phoneno']}</a></div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6 py-3 wow fadeInUp">
                                <div className="subhead">Contact Us</div>
                                <h2 className="title-section">Drop Us a Line</h2>
                                <div className="divider"></div>
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section border-top">
                    <div className="container">
                        <div className="text-center wow fadeInUp">
                            <div className="subhead">Our Blog</div>
                            <h2 className="title-section">Read our latest <span className="marked">News</span></h2>
                            <div className="divider mx-auto"></div>
                        </div>
                        <div className="row my-5 card-blog-row">
                            {
                                _.map(blogs.slice(0, 4), (blog, i) => {
                                    return (
                                        <div className="col-md-6 col-lg-3 py-3 wow fadeInUp" key={i}>
                                            <div className="card-blog">
                                                <div className="header">
                                                    <div className="avatar">
                                                        <img src={blog['photo']} alt={blog['author']} />
                                                    </div>
                                                    <div className="entry-footer">
                                                        <div className="post-author">{blog['author']}</div>
                                                        <Link to="#" className="post-date">{blog['date']}</Link>
                                                    </div>
                                                </div>
                                                <div className="body">
                                                    <div className="post-title"><Link to={blog['link']}>{blog['title']}</Link></div>
                                                    <div className="post-excerpt">{blog['description']}</div>
                                                </div>
                                                <div className="footer">
                                                    <Link to={blog['link']}>Read More <span className="mai-chevron-forward text-sm"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>

                        <div className="text-center">
                            <Link to="/blog" className="btn btn-outline-primary rounded-pill">Discover More</Link>
                        </div>
                    </div>
                </div>

                <div className="page-section client-section">
                    <div className="container-fluid">
                        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-5 justify-content-center">
                            {
                                _.map(clients, (client, i) => {
                                    return (
                                        <div className="item wow zoomIn" key={i}>
                                            <img src={client['logo']} alt={client['name']} />
                                        </div>
                                    );
                                 })
                            }
                        </div>
                    </div>
                </div>
            </main>
            
        </>
    )
}

export default Home;
