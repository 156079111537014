import React from "react";
import _ from "lodash";
import { Link, Outlet } from "react-router-dom";
import settings from "./settings";

function Footer() {
  let year = new Date();
  year = year.getFullYear();
  const contact = _.get(settings, "address", []);

  return (
    <>
      <footer className="page-footer">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-lg-3 py-3">
              <h3>
                Undash-<span className="text-primary">Cop.</span>
              </h3>
              <p>
                A new journey builder for many startups and small business to
                run there world into successful stories!
              </p>

              <p>
                <a href={`mailto:${contact["email"]}`}>{contact["email"]}</a>
              </p>
              <p>
                <a href={`tel:${contact["phoneno"]}`}>{contact["phoneno"]}</a>
              </p>
            </div>
            <div className="col-lg-3 py-3">
              <h5>Quick Links</h5>
              <ul className="footer-menu">
                <li>
                  <Link to="/services">How it works</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Security</Link>
                </li>
                <li>
                  <Link to="/#pricing">Pricing</Link>
                </li>
                <li>
                  <Link to="/refund-policy">Refund Policy</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Terms and Conditions</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 py-3">
              <h5>About Us</h5>
              <ul className="footer-menu">
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/career">Jobs</Link>
                </li>
                <li>
                  <Link to="/about">Our Teams</Link>
                </li>
                <li>
                  <Link to="/about">Testimonials</Link>
                </li>
                <li>
                  <Link to="/blog">News & Press</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 py-3">
              <h5>Subscribe</h5>
              <form action="#">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your mail.."
                />
              </form>

              <div className="sosmed-button mt-4">
                <Link to={contact["facebook"]} target="_blank">
                  <span className="mai-logo-facebook-f"></span>
                </Link>
                <Link to={contact["twitter"]} target="_blank">
                  <span className="mai-logo-twitter"></span>
                </Link>
                <Link to={contact["linkedin"]} target="_blank">
                  <span className="mai-logo-linkedin"></span>
                </Link>
                <Link to={contact["instagram"]} target="_blank">
                  <span className="mai-logo-instagram"></span>
                </Link>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 py-2">
              <p id="copyright">
                &copy; 2018-{year}{" "}
                <Link to="/">Undash-cop Private Limited</Link>. All rights
                reserved
              </p>
            </div>
            <div className="col-sm-6 py-2 text-right">
            <div className="d-inline-block px-3">
                <Link to="/refund-policy">Refunds</Link>
              </div>
              <div className="d-inline-block px-3">
                <Link to="/privacy-policy">Privacy</Link>
              </div>
              <div className="d-inline-block px-3">
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <Outlet />
    </>
  );
}

export default Footer;
