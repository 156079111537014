import React, { useEffect } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";

import About from "./About";
import Contact from "./Contact";
import Blog from "./Blog";
import BlogSingle from "./BlogSingle";
import Services from "./Services";
import Home from "./Home";
import Products from "./Products";
import Career from "./Careers";
import PrivacyPolicy from "./PrivacyPolicy";
import RefundPolicy from "./RefundPolicy";
import TermsAndConditions from "./TermsAndConditions";

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function Header() {
  let path = useLocation().pathname;
  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light navbar-float">
          <div className="container">
            <img
              src="../assets/img/logo.png"
              alt=""
              height="60px"
              width="60px"
            />
            <Link to="/" className="navbar-brand">
              Undash-<span className="text-primary">Cop</span>
            </Link>
            <button
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarContent"
              aria-controls="navbarContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="navbar-collapse collapse" id="navbarContent">
              <ul className="navbar-nav ml-lg-4 pt-3 pt-lg-0">
                <li
                  className={`nav-item ${
                    path === "/" || path === "" ? "active" : ""
                  }`}
                >
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
                <li className={`nav-item ${path === "/about" ? "active" : ""}`}>
                  <Link to="/about" className="nav-link">
                    About
                  </Link>
                </li>
                <li
                  className={`nav-item ${path === "/services" ? "active" : ""}`}
                >
                  <Link to="/services" className="nav-link">
                    Services
                  </Link>
                </li>
                <li
                  className={`nav-item ${path === "/products" ? "active" : ""}`}
                >
                  <Link to="/products" className="nav-link">
                    Products
                  </Link>
                </li>
                <li
                  className={`nav-item ${path === "/career" ? "active" : ""}`}
                >
                  <Link to="/career" className="nav-link">
                    Career
                  </Link>
                </li>
                {/* <li className={`nav-item ${path === '/blog' ? 'active': ''}`}>
                                    <Link to="/blog" className="nav-link">News</Link>
                                </li> */}
                <li
                  className={`nav-item ${path === "/contact" ? "active" : ""}`}
                >
                  <Link to="/contact" className="nav-link">
                    Contact
                  </Link>
                </li>
              </ul>

              <div className="ml-auto">
                <Link to="/contact" className="btn btn-outline rounded-pill">
                  Get a Quote
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="blog" element={<Blog />} />
        <Route path="/blog-single" element={<BlogSingle />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/career" element={<Career />} />
        <Route path="/products" element={<Products />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </>
  );
}

export default Header;
