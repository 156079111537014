import React from "react";
import _ from "lodash";
import HubspotForm from 'react-hubspot-form';
import settings from "./settings";


const CareerForm = () => {
    return (
        <>
        <HubspotForm
            portalId={_.get(settings, ["hs_form_contact", "portalId"], "")}
            formId={_.get(settings, ["hs_form_contact", "jobFormId"], "")}
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => {
                setTimeout(() => document.querySelector('iframe').contentDocument.body.querySelector('.hubspot-link__container').style.display = 'none', 200)
            }}
            loading={<div>Loading...</div>}
        />
        </>
    );
}

export default CareerForm;
