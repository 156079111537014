import React from "react";
import { Link } from 'react-router-dom';
import _ from "lodash";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import settings from "./settings";


function About() {
    const revenue = _.get(settings, "revenue", {});
    const reviews = _.get(settings, "reviews", []);
    const clients = _.get(settings, "clients", []);
    const members = _.get(settings, "members", []);

    return (
        <>
            <header>
                <div className="container mt-5">
                    <div className="page-banner">
                        <div className="row justify-content-center align-items-center h-100">
                            <div className="col-md-6">
                                <nav aria-label="Breadcrumb">
                                    <ul className="breadcrumb justify-content-center py-0 bg-transparent">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">About</li>
                                    </ul>
                                </nav>
                                <h1 className="text-center">About Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="back-to-top"></div>
            <main>
                <div className="page-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 py-3">
                                <div className="img-fluid text-center">
                                    <img src="../assets/img/bg_image_2.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 py-3 pr-lg-5">
                                <h2 className="title-section">We're <span className="marked">Dynamic</span> Team of Creatives People</h2>
                                <div className="divider"></div>
                                <p>{settings['descriptorTag']}</p>
                                <Link to="#" className="btn btn-primary">More Details</Link>
                                <Link to="#" className="btn btn-outline border ml-2">Success Stories</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section counter-section">
                    <div className="container">
                        <div className="row align-items-center text-center">
                            <div className="col-lg-4">
                                <p>Total Invest</p>
                                <h2>{settings['currency']}<span className="number" data-number={revenue['totalInvest']}></span></h2>
                            </div>
                            <div className="col-lg-4">
                                <p>Yearly Revenue</p>
                                <h2>{settings['currency']}<span className="number" data-number={revenue['yearlyRevenue']}></span></h2>
                            </div>
                            <div className="col-lg-4">
                                <p>Growth Ration</p>
                                <h2><span className="number" data-number={revenue['growthRatio']}></span>%</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section">
                    <div className="container">
                        <div className="text-center wow fadeInUp">
                            <h2 className="title-section">Our <span className="marked">Undash-cop</span> Team</h2>
                            <div className="divider mx-auto"></div>
                            <p>Every Employee of the Undash-cop team represents the spirit of our company: driven, committed, and striving to serve our startup clients. We are self-starters with diverse experience and skill sets that not only aim to be the best workforce, but are also well rounded and talented.</p>
                        </div>
                    </div>
                    {
                        _.map(members, (person, i) => {
                            return (
                                <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 py-3">
                                        <div className="img-fluid text-center">
                                            <img src={person['photo']} alt={person['name']} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 py-3 pr-lg-6">
                                        <h2 className="team-section"><span className="marked">{person['name']}</span><br/> {person['title']}</h2>
                                        <div className="divider"></div>
                                        <p>{person['about']}</p>
                                        <div className="sosmed-button mt-4">
                                            {person['facebook'] && <a href={person['facebook']} target="_blank" rel="noreferrer"><span className="mai-logo-facebook-f"></span></a>}
                                            {person['twitter'] && <a href={person['twitter']} target="_blank" rel="noreferrer"><span className="mai-logo-twitter"></span></a>}
                                            {person['linkedin'] && <a href={person['linkedin']} target="_blank" rel="noreferrer"><span className="mai-logo-linkedin"></span></a>}
                                            {person['instagram'] && <a href={person['instagram']} target="_blank" rel="noreferrer"><span className="mai-logo-instagram"></span></a>}
                                            {person['website'] && <a href={person['website']} target="_blank" rel="noreferrer"><span className="mai-logo-rss"></span></a>}
                                        </div>
                                        {/* <Link to="#" className="btn btn-primary">More Details</Link> */}
                                        {/* <Link to="#" className="btn btn-outline border ml-2">Success Stories</Link> */}
                                    </div>
                                </div>
                                </div>
                            );
                        })
                    }
                </div>

                <div className="page-section bg-light">
                    <div className="container">
                        <div id="testimonials">
                            {reviews ? 
                                <OwlCarousel className="owl-carousel wow fadeInUp owl-theme" margin={10} items={1} autoHeight rewind autoplayHoverPause loop autoplay animateOut={'fadeOut'} animateIn={'fadeIn'} >
                                    {
                                        _.map(reviews, (review, i) => {
                                            return (
                                                <div className="item" key={i}>
                                                    <div className="row align-items-center">
                                                        <div className="col-md-6 py-3">
                                                            <div className="testi-image">
                                                                <img src={review['photo']} alt={review['name']} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 py-3">
                                                            <div className="testi-content">
                                                                <p>{review['comment']}</p>
                                                                <div className="entry-footer">
                                                                    <strong>{review['name']}</strong> &mdash; <span className="text-grey">{review['designation']} {review['company']}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </OwlCarousel>
                            : null }
                        </div>
                    </div>
                </div>

                <div className="page-section client-section">
                    <div className="container-fluid">
                        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-5 justify-content-center">
                            {
                                _.map(clients, (client, i) => {
                                    return (
                                        <div className="item wow zoomIn" key={i}>
                                            <img src={client['logo']} alt={client['name']} />
                                        </div>
                                    );
                                 })
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default About;
