const settings = {
  currency: "₹",
  revenue: {
    totalInvest: "816278",
    yearlyRevenue: "1216422",
    growthRatio: "103",
  },
  address: {
    headQuarters:
      "Undash-cop Private Limited, No. 2234, 2nd & 3rd Floor, Kamalamma Nanjappa Building, 3rd A cross, behind New bus stop, M V Extenstion, Hoskote, Karnataka 562114",
    devOffice:
      "Undash-cop Private Limited, L5, The Hive, VR Bengaluru, Level 5, ITPL Main Rd, Devasandra Industrial Estate, Bengaluru, Karnataka 560048",
    email: "info@undash-cop.com",
    phoneno: "+91 866 015 8001",
    mapLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4179.970424802299!2d77.78284361482304!3d13.069905290791402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x98aa9951e31c7b55!2sUndash-cop%20Private%20Limited!5e1!3m2!1sen!2sin!4v1594057443808!5m2!1sen!2sin",
    facebook: "//www.facebook.com/undashcop7",
    twitter: "//twitter.com/UndashI",
    linkedin: "//www.linkedin.com/company/undash-cop",
    instagram: "//www.instagram.com/undashcop7",
  },
  features: [
    {
      title: "Complete solutions for global organisations",
      description:
        "Copywrite, blogpublic realations content translation, SEO, analytics.",
    },
    {
      title: "Complete solutions for IT/Hardware and networking support",
      description:
        "Network solutions, Repair services, Installation of servers, routers, firewalls, switches, printers and more.",
    },
    {
      title: "Provide Product Engineering advice by our advisor",
      description:
        "Services and Solutions across the entire product engineering spectrum - right from consulting to designing the user interface, from developing to testing and deploying the product.",
    },
    {
      title: "Provide financial advisor tools for organisations",
      description:
        "Complete Finacial Managment tools, Employee CRM, ATS, Fin Tools, and lot more.",
    },
    {
      title: "Provide Ease use of E-Commerce Platform Solutions",
      description:
        "In essence, e-Commerce website business activities focuse on selling, buying, and product catalogue transactions.",
    },
    {
      title: "Provide UI/UX Approaches and Software Solutions",
      description:
        "We offer you unique web design with interactive UI that have better user experience, stand out to give you an edge over competitors, lesser bounce rates, less loading time. And Software Solutions for Integrations.",
    },
  ],
  descriptorTag:
    "We provide complete software solutions to startups & small business to looking for partner for their digital media, design & dev lead generation & communication, software developement, finance management, CRM, etc.",
  benefits: [
    {
      title: "High Performance",
      description: "High Performance Tools with Faster Response Rates",
    },
    {
      title: "Friendly Prices",
      description: "Cheap and afordable prices compared with competitors",
    },
    {
      title: "No time-confusing",
      description: "100% On time product delivery rates without delay",
    },
  ],
  pricing: [
    {
      title: "Membership",
      priceMonthly: "30",
      priceAnnualy: "330",
      recommended: false,
      icon: "mai-people",
      discount: "10",
    },
    {
      title: "Dedicated",
      priceMonthly: "60",
      priceAnnualy: "650",
      recommended: true,
      icon: "mai-business",
      discount: "12",
    },
    {
      title: "Private",
      priceMonthly: "90",
      priceAnnualy: "1000",
      recommended: false,
      icon: "mai-rocket-outline",
      discount: "15",
    },
  ],
  reviews: [
    {
      name: "Sowmya",
      designation: "CEO",
      comment:
        "Employee Welfare is one of the main focuses of the company and all employees are given ample opportunities to grow and showcase their potential.",
      company: "Letro",
      photo: "../assets/img/person/customer5.jpg",
    },
    {
      name: "Amith Kumar",
      designation: "Project Manager",
      comment:
        "Despite being such a good company, it provides each of its employees a really good working experience. The employees are made to feel part of the ITC Infotech family the moment they enter the Office.",
      company: "Slurin Group",
      photo: "../assets/img/person/customer6.jpg",
    },
    {
      name: "Lilly Margret",
      designation: "Business Analyst",
      comment:
        "Work life balance, Work from home, Brand in the market, Job Security, Work satisfaction",
      company: "Letro",
      photo: "../assets/img/person/customer4.jpg",
    },
    {
      name: "Vikshith",
      designation: "Project Manager",
      comment:
        "Overall good experience. Great people and culture. Have gained exposure to various situations. People initiatives yielded good results. In-depth process knowledge helped me to steer the operations effectively and optimally.",
      company: "fortuny",
      photo: "../assets/img/person/customer8.jpg",
    },
  ],
  blogs: [
    {
      author: "Sam Newman",
      date: "23 Apr 2020",
      photo: "../assets/img/person/person_1.jpg",
      title: "What is Business Management?",
      description: "",
      link: "/blog-single",
    },
    {
      author: "Sam Newman",
      date: "23 Apr 2020",
      photo: "../assets/img/person/person_1.jpg",
      title: "What is Business Management?",
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
      link: "/blog-single",
    },
    {
      author: "Sam Newman",
      date: "23 Apr 2020",
      photo: "../assets/img/person/person_2.jpg",
      title: "What is Business Management?",
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
      link: "/blog-single",
    },
    {
      author: "Sam Newman",
      date: "23 Apr 2020",
      photo: "../assets/img/person/person_3.jpg",
      title: "What is Business Management?",
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
      link: "/blog-single",
    },
    {
      author: "Sam Newman",
      date: "23 Apr 2020",
      photo: "../assets/img/person/person_1.jpg",
      title: "What is Business Management?",
      description: "",
      link: "/blog-single",
    },
    {
      author: "Sam Newman",
      date: "23 Apr 2020",
      photo: "../assets/img/person/person_1.jpg",
      title: "What is Business Management?",
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
      link: "/blog-single",
    },
    {
      author: "Sam Newman",
      date: "23 Apr 2020",
      photo: "../assets/img/person/person_2.jpg",
      title: "What is Business Management?",
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
      link: "/blog-single",
    },
    {
      author: "Sam Newman",
      date: "23 Apr 2020",
      photo: "../assets/img/person/person_3.jpg",
      title: "What is Business Management?",
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
      link: "/blog-single",
    },
    {
      author: "Sam Newman",
      date: "23 Apr 2020",
      photo: "../assets/img/person/person_1.jpg",
      title: "What is Business Management?",
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
      link: "/blog-single",
    },
  ],
  clients: [
    {
      name: "airbnb",
      logo: "../assets/img/clients/airbnb.png",
    },
    {
      name: "google",
      logo: "../assets/img/clients/google.png",
    },
    {
      name: "stripe",
      logo: "../assets/img/clients/stripe.png",
    },
    {
      name: "paypal",
      logo: "../assets/img/clients/paypal.png",
    },
    {
      name: "mailchimp",
      logo: "../assets/img/clients/mailchimp.png",
    },
  ],
  products: [
    {
      name: "EUP Dashboard",
      description:
        "EUP Dashboard is a complete startup CRM for Handling Employee data and Financial data with ATS and Payroll Processing",
      photo: "https://eup.undash-cop.com/static/media/logo.60cde574.svg",
      link: "//eup.undash-cop.com",
    },
    {
      name: "Undash-cop Studio",
      description:
        "Undash-cop Studio Provides various HTML Site Templates which can be choosen for the deployment of your website in just 72 hours",
      photo: "../assets/img/demos.png",
      link: "//demos.undash-cop.com",
    },
    {
      name: "Serviso",
      description:
        "Serviso is a simply Invoice and Acknowledgement generation software for few service centers and micro sales companies",
      photo: "https://serviso.undash-cop.com/static/logo.svg",
      link: "//serviso.undash-cop.com",
    },
    {
      name: "Futuro Expenses",
      description:
        "Futuro Expenses is a comprehensive app that lets users do everything from track spending to review their investment portfolio",
      photo: "../assets/img/future-expense.png",
      link: "//future.undash-cop.com",
    },
    {
      name: "YRB Services",
      description:
        "YRB Services is a IT Hardware service station, which is capable of handling all the business records and tools for handing service station.",
      photo: "../assets/img/yrb.png",
      link: "//yrb-services.undash-cop.com",
    },
  ],
  hs_form_contact: {
    portalId: "9104225",
    contactFormId: "ba9c8ad0-b250-481d-b2b4-b59c4945b849",
    jobFormId: "dd76cc4a-0142-41ca-b5c7-9ff4cc914026",
  },
  members: [
    {
      name: "Suman KN",
      title: "Founder | Chief Executive Officer",
      about:
        "There's something to be said about working with passionate business owners that truly love what they do. I’m no different. I started my business because I honestly enjoy coming to work every day to create something beautiful that has the ability to impact others. But Our weekends has a different passion off the work in the very own generous play of racing and drag race mastering, Since it is the one which made me stand till the date.",
      photo:
        "https://drive.google.com/thumbnail?id=1sPGoC4VTi1TBbMEM9rLeZxu7nqKMf3qp",
      website: "https://sumankn.undash-cop.com",
      linkedin: "https://in.linkedin.com/in/suman-kn",
      instagram: "https://www.instagram.com/suman_kn_racer",
      twitter: "https://twitter.com/suman_kn",
      facebook: "https://www.facebook.com/suman.kn.585",
    },
    {
      name: "Yashwanth H R",
      title: "Co-Founder | Chief Operating Officer",
      about:
        "One of the Co-founder with high tech skills in balancing the responsibility of our support team and our resources. 8 year of Experience in Sourcing and resource Management.",
      photo:
        "https://drive.google.com/thumbnail?id=1W1ARN1r2w0xlH-vA0B04mhlUkDJ3LlIz",
      website: "",
      linkedin: "https://in.linkedin.com/in/yashwanth-hr-naidu-b6a4b4149",
      instagram: "https://www.instagram.com/yashwanth_hr_naidu",
      twitter: "https://twitter.com/hrnaidu",
      facebook: "https://www.facebook.com/yashwanth.naidu.587",
    },
    {
      name: "Mohammed Nawaz A",
      title: "Co-Founder | Chief Financial Officer",
      about:
        "As the co-founder is a person who worked for more over 9 years had a great challenge in managing all the stuff in his industry with the small scale budgets",
      photo:
        "https://drive.google.com/thumbnail?id=1qWi_KgOaDRlJEiBjrW6zDZViWK5NCP0T",
      website: "",
      linkedin: "https://in.linkedin.com/in/nawaz-a",
      instagram: "https://www.instagram.com/nawazf37",
      twitter: "",
      facebook: "https://www.facebook.com/mohammed.nawaz.921677",
    },
    {
      name: "Karthik",
      title: "Chief Technological Officer",
      about:
        "Best in site and bang the technologies, Make way for inventions all the times",
      photo: "",
      website: "",
      linkedin: "https://www.linkedin.com/in/karthik-r-352600103",
      instagram: "https://www.instagram.com/karthikhr7",
      twitter: "https://twitter.com/karthikbdb",
      facebook: "https://www.facebook.com/karthik.bdb",
    },
    {
      name: "Purushottam Gour",
      title: "Cheif Product Officer",
      about:
        "Most of the Products comes handy when a correct Product Management has been taken care.  ",
      photo:
        "https://drive.google.com/thumbnail?id=1XNO92CHpgnSGwM38hzYOfOUKhGREeuLq",
      website: "",
      linkedin: "https://www.linkedin.com/in/purushottam-gour-2a96ab148/",
      instagram: "https://www.instagram.com/purushottam.gour/",
      twitter: "",
      facebook: "https://www.facebook.com/prince.gour.50",
    },
  ],
};

export default settings;
